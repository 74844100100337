.main-container {
    width: 70%;
    height: 375px;
    margin: 50px auto;
    text-align: center;
    background-color: #FFFFFF;
    padding: 5px;
    box-sizing: border-box;
}

.inner-container {
    position: relative;
    width: 96%;
    height: 320px;
    margin: 22px auto;
    border: 1px solid #000000;
    background-color: #F0F6FA;
    padding: 5px;
    box-sizing: border-box;
}
.unsubscribe-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center
}

.thank-you-text {
    width: 90%;
    height: 5px;
    padding-top: 8px;
    font: Segoe UI;
    font-weight: 520;
    font-size: x-large;
    letter-spacing: 0px;
    color: #00497B;
    opacity: 1;
}

.confirm-text {
    width: 90%;
    height: 40px;
    font-weight: 425;
    font-size: 130%;
    font: Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.button-container {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }
  
  .button {
    margin: 8px;
    padding: 15px 15px;
    font-size: 16px;
    background-color: #00ADE8;
    font-family: normal normal 16px/21px Segoe UI;
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .ack-unsubscribed-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 120px auto;
}
  
  /* Adjust styles for mobile devices */
  @media (max-width: 768px) {
    .button-container {
      flex-direction: column;
      align-items: center;
    }
    .button {
        margin: 5px;
        padding: 10px 10px;
    }
    .ack-unsubscribed-container{
        margin: 90px auto;
    }
  }
  
.privacy-policy-container {
    position: absolute;
    bottom: 20px;
    right: 20px;
}
.privacy-policy-ack-container {
    width: 95%;
    display: flex;
    justify-content: flex-end;
    align-items: start;
    margin: 40px;
}

.privacy-policy-text {
    font: normal normal normal 18px/24px Roboto;
    color: #0087E0;
    text-align: end;
}